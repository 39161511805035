
import React from "react"
export const columns =  [
    {
        accessorKey: 'pointToPointBookId',
        header: 'ID',
      },  


      {
        accessorKey: 'tripType',
        header: 'Trip Type'
        
      },
      {
        accessorKey: 'pickupPhysicalAddress',
        header: 'PickUp physical Address',
      },

      {
        accessorKey: 'dropoffPhysicalAddress',
        header: 'Dropoff Physical Address',
      },

    {
      accessorKey: 'paymentStatus',
      header: 'Payment Status',
    
  },

  {
    accessorKey: 'bookingStatus',
    header: 'Booking Status',
},

{
  accessorKey: 'pickupDateTime',
  header: 'pickup-Date-Time',
},
{
  accessorKey: 'passengerFullName',
  header: 'passenger-FullName',
},

{
  accessorKey: 'passengerCellPhone',
  header: 'passenger-Cell-Phone',
},

{
  accessorKey: 'passengerEmail',
  header: 'passenger-Email',
},

{
  accessorKey: 'numberOfPassengers',
  header: 'Number-Of-Passengers',
},


  ]

 