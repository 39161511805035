import React from "react"
export const columns =  [
    {
        accessorKey: 'hourlyCharterBookId',
        header: 'ID',
      },  
      {
        accessorKey: 'selectedHours',
        header: 'selected-Hours',
      },  

      {
        accessorKey: 'paymentStatus',
        header: 'payment-Status'
        
      },

      {
        accessorKey: 'bookingStatus',
        header: 'booking-Status'
        
      },
      {
        accessorKey: 'pickupPhysicalAddress',
        header: 'pickup-Physical-Address'
        
      },

      {
        accessorKey: 'dropoffPhysicalAddress',
        header: 'dropoff-Physical-Address'
        
      },

      {
        accessorKey: 'pickupDateTime',
        header: 'pickup-Date-Time',
      },

      {
        accessorKey: 'passengerFullName',
        header: 'passenger-FullName'
        
      },


      {
        accessorKey: 'passengerCellPhone',
        header: 'passenger-Cell-Phone'
        
      },

      {
        accessorKey: 'passengerEmail',
        header: 'passenger-Email'
        
      },

      {
        accessorKey: 'numberOfPassengers',
        header: 'number-Of-Passengers'
        
      },

      {
        accessorKey: 'numberOfSuitcases',
        header: 'number-Of-Suitcases'
        
      },
    

      {
        accessorKey: 'occasion',
        header: 'Occasion',
      },

  ]
