
import React from "react"
export const columns =  [
    {
        accessorKey: 'airportBookId',
        header: 'ID',
      },    
      {
        accessorKey: 'tripType',
        header: 'Trip Type'
        
      },
      
      {
        accessorKey: 'paymentStatus',
        header: 'Payment-Status',
      },
      {
        accessorKey: 'bookingStatus',
        header: 'Booking-Status',
      },

      {
        accessorKey: 'passengerCellPhone',
        header: 'Passenger-Cell-Phone',
      },
      {
        accessorKey: 'passengerEmail',
        header: 'Passenger-Email',
      },
      {
        accessorKey: 'numberOfPassengers',
        header: 'number-Of-Passengers',
      },

      {
        accessorKey: 'accommodationAddress',
        header: 'Accommodation-Address',
      }, 
      
    
      {
        accessorKey: 'airline',
        header: 'Airline',
      },
      
      {
        accessorKey: 'arrivalFlightNumber',
        header: 'Arrival-Flight-Number',
      },
      {
        accessorKey: 'returnAirline',
        header: 'Return-Airline',
      },
      {
        accessorKey: 'returnFlightNumber',
        header: 'Return-Flight-Number',
      },


  ]

 